<template>
	<div>
		<div id="header" class="header navbar navbar-default navbar-expand-lg navbar-fixed-top">
			<div class="container">
				<a href="/" class="navbar-brand">
					<span class="brand-logo"></span>
					<span class="brand-text">SISTEMA DE PRODUCCIÓN</span>
				</a>
				<div class="collapse navbar-collapse" id="header-navbar">
					<ul class="navbar-nav ml-auto" :class="{ show: menuVisible }">
						<li class="nav-item dropdown">
							<a class="nav-link" href="/">INICIO</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/#/Autoridades">AUTORIDADES</a>
						</li>
						<li class="nav-item">
							<a class="nav-link dropdown-toggle" href="/#/Calendario" data-toggle="dropdown">UNIDADES DE
								PRODUCCIÓN</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" href="#">BPE-I "MARTÍN CÁRDENAS"</a>
								<a class="dropdown-item" href="#">BPE-II "CNL. OSCAR MOSCOSO"</a>
								<a class="dropdown-item" href="#">BPE-III "CNL. EDUARDO PACCIERI"</a>
								<a class="dropdown-item" href="#">BPE-IV "TTE. VICTOR EDUARDO"</a>
								<a class="dropdown-item" href="#">BPE-V "CNL. JULIO SANJINÉS"</a>
								<a class="dropdown-item" href="/#/Santos">BPE-VI "MY. JOSÉ SANTOS VARGAS"</a>
								<a class="dropdown-item" href="#">BPE-III "CNL. EDUARDO PACCIERI"</a>
								<a class="dropdown-item" href="#">HARÁS DEL EJÉRCITO</a>
							</div>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="#">REPOSITORIO DEL DPTO. VII.</a>
						</li>
						<li class="divider"></li>
						<li>
							<a href="/#/login">
								<span class="d-none d-xl-inline">Login</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div id="page-title" class="page-title has-bg">
			<div class="bg-cover" data-paroller="true" data-paroller-factor="0.5" data-paroller-factor-xs="0.2"
				style="background: url(/assets/img/prod/bannerprod.png) center 0px / cover no-repeat"></div>
			<div class="container">
				<p>Página Oficial</p>
			</div>
		</div>

		<div id="content" class="content">
			<div class="container">
				<div class="row row-space-30">
					<div class="col-lg-9">
						<ul class="post-list">
							<li>
								<div class="post-left-info">
									<div class="post-date">
										<span class="day">03</span>
										<span class="month">MAYO</span>
									</div>
									<div class="post-likes">
										<i class="fa fa-heart text-primary"></i>
										<span class="number">10</span>
									</div>
								</div>
								<div class="post-content">
									<div class="post-image post-image-with-carousel">
										<div id="carousel-post" class="carousel slide" data-ride="carousel">
											<ol class="carousel-indicators">
												<li data-target="#carousel-post" data-slide-to="0" class="active"></li>
												<li data-target="#carousel-post" data-slide-to="1"></li>
												<li data-target="#carousel-post" data-slide-to="2"></li>
											</ol>
											<div class="carousel-inner">
												<div class="carousel-item active">
													<a href="post_detail.html"><img class="d-block w-100"
															src="/assets/img/prod/campo1.jpeg" alt="" /></a>
												</div>
												<div class="carousel-item">
													<a href="post_detail.html"><img class="d-block w-100"
															src="/assets/img/prod/campo2.jpeg" alt="" /></a>
												</div>
												<div class="carousel-item">
													<a href="post_detail.html"><img class="d-block w-100"
															src="/assets/img/prod/campo3.jpeg" alt="" /></a>
												</div>
												<div class="carousel-item">
													<a href="post_detail.html"><img class="d-block w-100"
															src="/assets/img/prod/campo4.jpeg" alt="" /></a>
												</div>
												<div class="carousel-item">
													<a href="post_detail.html"><img class="d-block w-100"
															src="/assets/img/prod/campo5.jpeg" alt="" /></a>
												</div>
												<div class="carousel-item">
													<a href="post_detail.html"><img class="d-block w-100"
															src="/assets/img/prod/campo6.jpeg" alt="" /></a>
												</div>
											</div>
											<a class="carousel-control-prev" href="#carousel-post" role="button"
												data-slide="prev">
												<span class="fa fa-chevron-left" aria-hidden="true"></span>
											</a>
											<a class="carousel-control-next" href="#carousel-post" role="button"
												data-slide="next">
												<span class="fa fa-chevron-right" aria-hidden="true"></span>
											</a>
										</div>
									</div>
									<div class="post-info">
										<h4 class="post-title">
											<a href="post_detail.html">Actividades Sobresalientes de los Batalllones</a>
										</h4>
										<div class="post-by">
											Publicado Por <a href="#"> Administrador</a> <span class="divider">
											</span> 2 Comentarios
										</div>
										<div class="post-desc">
											El Batallón de Producción "Santos Vargas",
											ubicado en la comunidad de AZAFRANAL - Desaguadero,
											ha demostrado un compromiso excepcional con el desarrollo
											agrícola y ganadero de la región,
											destacándose por sus actividades sobresalientes
											que han impulsado la economía local y mejorado
											la calidad de vida de sus habitantes.
										</div>
									</div>
									<div class="read-btn-container">
										<a href="post_detail.html">Leer Artículo Completo<i
												class="fa fa-angle-double-right"></i></a>
									</div>
								</div>
							</li>
							<li>
								<div class="post-left-info">
									<div class="post-date">
										<span class="day">21</span>
										<span class="month">ABRIL</span>
									</div>
									<div class="post-likes">
										<i class="fa fa-heart text-primary"></i>
										<span class="number">10</span>
									</div>
								</div>
								<div class="post-content">
									<div class="post-image">
										<a href="post_detail.html">
											<div class="post-image-cover"
												style="background-image: url('/assets/img/prod/campo5.jpeg');">
											</div>
										</a>
									</div>

									<div class="post-info">
										<h4 class="post-title">
											<a href="post_detail.html">LLEGADA DE 500 POLLITAS PONEDORAS</a>
										</h4>
										<div class="post-by">
											Publicado Por <a href="#">admin</a> <span class="divider">|</span>
											<span class="divider">|</span> 1 Comentario
										</div>
										<div class="post-desc">
											El Batallón de Producción "Santos Vargas" en AZAFRANAL
											- Desaguadero recibió hoy un emocionante envío de 500
											pollitas ponedoras, marcando un hito importante en
											sus esfuerzos por fortalecer la producción de huevos
											en la región. La llegada de estas aves jóvenes y
											saludables representa un paso significativo hacia
											la autosuficiencia alimentaria y el desarrollo económico local.
										</div>
									</div>
									<div class="read-btn-container">
										<a href="post_detail.html" class="read-btn">Leer Artículo Completo <i
												class="fa fa-angle-double-right"></i></a>
									</div>
								</div>
							</li>
							<li>
								<div class="post-left-info">
									<div class="post-date">
										<span class="day">18</span>
										<span class="month">ABRIL</span>
									</div>
									<div class="post-likes">
										<i class="fa fa-heart text-primary"></i>
										<span class="number">1</span>
									</div>
								</div>
								<div class="post-content">
									<div class="post-video">
										<div class="embed-responsive embed-responsive-16by9">
											<iframe class="embed-responsive-item"
												src="https://www.youtube.com/embed/_5aKcpAhTOk"
												allowfullscreen></iframe>
										</div>
									</div>
									<div class="post-info">
										<h4 class="post-title">
											<a href="post_detail.html">Video</a>
										</h4>
										<div class="post-by">
											Publicado Por <a href="#">admin</a> <span class="divider">|</span>
											<span class="divider">|</span> 1 Comentario
										</div>
										<div class="post-desc">
											El batallón ha preparado cuidadosamente
											las instalaciones para dar la bienvenida a las
											nuevas integrantes, asegurando que cuenten con
											un ambiente cómodo y seguro para su crecimiento
											y desarrollo. Se han instalado modernos sistemas
											de alimentación y bebederos, así como un adecuado
											sistema de ventilación, para garantizar el bienestar
											de las aves y maximizar su producción.
										</div>
									</div>
									<div class="read-btn-container">
										<a href="post_detail.html">Leer Artículo Completo <i
												class="fa fa-angle-double-right"></i></a>
									</div>
								</div>
							</li>
						</ul>
						<div class="section-container">
							<div class="pagination-container">
								<ul class="pagination justify-content-center">
									<li class="page-item disabled"><a class="page-link" href="javascript:;">Prev</a>
									</li>
									<li class="page-item active"><a class="page-link" href="javascript:;">1</a></li>
									<li class="page-item"><a class="page-link" href="javascript:;">2</a></li>
									<li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
									<li class="page-item"><span class="text">...</span></li>
									<li class="page-item"><a class="page-link" href="javascript:;">1797</a></li>
									<li class="page-item"><a class="page-link" href="javascript:;">Next</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="section-container">
							<div class="input-group sidebar-search">
								<input type="text" class="form-control" placeholder="Buscar Noticias" />
								<span class="input-group-append">
									<button class="btn btn-inverse" type="button"><i class="fa fa-search"></i></button>
								</span>
							</div>
						</div>
						<div class="section-container">
							<h4 class="section-title"><span>BATALLONES DE PROD. Y ECO.</span></h4>
							<ul class="sidebar-list">
								<li><a href="#">BPE-I "MARTÍN CÁRDENAS"</a></li>
								<li><a href="#">BPE-II "CNL. OSCAR MOSCOSO"</a></li>
								<li><a href="#">BPE-III "CNL. EDUARDO PACCIERI"</a></li>
								<li><a href="#">BPE-IV "TTE. VICTOR EDUARDO"</a></li>
								<li><a href="#">BPE-V "CNL. JULIO SANJINÉS"</a></li>
								<li><a href="/#/Santos">BPE-VI "MY. JOSÉ SANTOS VARGAS"</a></li>
								<li><a href="#">HARÁS DEL EJÉRCITO </a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="footer" class="footer">
			<div class="container">
				<div class="row">
					<div class="col-md-3 col-6">
						<div class="section-container">
							<h4 class="footer-title">Categories</h4>
							<ul class="categories">
								<li><a href="#">Sports</a></li>
							</ul>
						</div>
					</div>
					<div class="col-md-3 col-6">
						<div class="section-container">
							<h4 class="footer-title">Archives</h4>
							<ul class="archives">
								<li><a href="#">June 2018</a> <span class="total">(102)</span></li>
							</ul>
						</div>
					</div>
					<div class="col-md-3 col-6">
						<div class="section-container">
							<h4 class="footer-title">Recent Posts</h4>
							<ul class="recent-post">
								<li>
									<h4>
										<a href="#">Nam ut urna hendrerit</a>
										<span class="time">February 22, 2018</span>
									</h4>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-3 col-6">
						<div class="section-container">
							<h4 class="footer-title">About Color Admin</h4>
							<address>
								<strong>Twitter, Inc.</strong><br />
								795 Folsom Ave, Suite 600<br />
								San Francisco, CA 94107<br />
								P: (123) 456-7890<br />
							</address>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="footer-copyright" class="footer-copyright">
			<div class="container">
				<span class="copyright">&copy; 2024 SeanTheme All Right Reserved</span>
				<ul class="social-media-list mt-2 mt-sm-0 float-none float-sm-right">
					<li><a href="#"><i class="fab fa-facebook"></i></a></li>
					<li><a href="#"><i class="fab fa-google-plus"></i></a></li>
					<li><a href="#"><i class="fab fa-instagram"></i></a></li>
					<li><a href="#"><i class="fab fa-twitter"></i></a></li>
					<li><a href="#"><i class="fa fa-rss"></i></a></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HomePage",
	data() {
		return {
			menuVisible: false,
		};
	},
	methods: {
		toggleMenu() {
			this.menuVisible = !this.menuVisible;
		},
	},
};
</script>

<style scoped>
#header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	background-color: #fff;
}

#page-title {
	margin-top: 80px;
	z-index: 1;
}

.page-title .bg-cover {
	z-index: 0;
}

#content {
	z-index: 0;
}

.carousel-inner img {
	max-width: 100%;
	height: auto;
}

.content {
	margin-top: 70px;
}

.page-content-wrapper {
	padding-top: 10px;
}

.navbar {
	z-index: 1001;
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #333;
	padding: 1rem;
}

.navbar-brand {
	color: #fff;
	text-decoration: none;
	font-size: 1.5rem;
}

.navbar-toggler {
	display: none;
	cursor: pointer;
	color: #fff;
	font-size: 1.2rem;
}

.navbar-nav {
	display: flex;
	list-style: none;
}

.nav-item {
	position: relative;
}

.nav-link {
	color: #0f0e0e !important;
	text-decoration: none;
	padding: 0.5rem 1rem;
	display: block;
}

.dropdown-menu {
	display: none;
	position: absolute;
	background-color: #f9f5f5;
	list-style: none;
	margin: 0;
	padding: 10;
}

.dropdown-item {
	padding: 0.5rem 1rem;
	color: #080808 !important;
	text-decoration: none;
}

.nav-item:hover .dropdown-menu {
	display: block;
}

@media (max-width: 768px) {
	.navbar-nav {
		display: none;
		flex-direction: column;
		width: 100%;
	}

	.nav-item {
		width: 100%;
	}

	.navbar-toggler {
		display: block;
	}

	.navbar-nav.show {
		display: flex;
	}
}
</style>