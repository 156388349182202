import MainService from "@/services/MainService.js";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import PageOptions from '../../config/PageOptions.vue';
import DatePicker from 'vue2-datepicker'
import BarChart from '@/components/vue-chartjs/BarChart'
import 'vue2-datepicker/index.css'

Vue.use(VueAxios, axios);

export default {
    name: "HomePage",
    data() {
        return {
            ss: new MainService(),
            loginRequest: {},
            errorBag: {},
            name: process.env.VUE_APP_TITLE,
            bg: {
                activeImg: '/assets/img/login-bg/login-bg-17.jpg',
                bg1: { active: true, img: '/assets/img/login-bg/login-bg-17.jpg' },
                bg2: { active: false, img: '/assets/img/login-bg/login-bg-16.jpg' },
                bg3: { active: false, img: '/assets/img/login-bg/login-bg-15.jpg' },
                bg4: { active: false, img: '/assets/img/login-bg/login-bg-14.jpg' },
                bg5: { active: false, img: '/assets/img/login-bg/login-bg-13.jpg' },
                bg6: { active: false, img: '/assets/img/login-bg/login-bg-12.jpg' }
            },
            Cebolla: {},
            cebollaImages: [
                { src: '/assets/img/productos/cebolla2.jpeg', caption: 'Cebolla 1', text: 'Descripción de la Cebolla 1' },
                { src: '/assets/img/productos/cebolla2.jpeg', caption: 'Cebolla 2', text: 'Descripción de la Cebolla 2' },
                { src: '/assets/img/productos/cebolla2.jpeg', caption: 'Cebolla 3', text: 'Descripción de la Cebolla 3' }
            ],
            cebollaData: {
                especialidad: 'Datos sobre la especialidad de la cebolla',
                descripcion: 'Descripción detallada del producto cebolla'
            },
            menuVisible: false,
            dateRange: {
                start: new Date(2024, 8, 1), // septiembre es el mes 8 (0-indexado)
                end: new Date(2024, 10, 30) // noviembre es el mes 10 (0-indexado)
            },
            timelineItems: [
                    {
                      dateStart: '2024-09-01',
                      dateEnd: '2024-11-30',
                      producto: 'Cañawa',
                      grupo: 'Cereales',
                      superficieTotal: '0,25',
                      superficieEfectiva: '0,25',
                      cantidadCosechada: '1',
                      tipoCultivo: 'Independiente',
                      campaña: 'Primavera',
                      mesesCampaña: 'sep-oct-nov',
                      tiempoCosecha: '3 a 4 meses'
                    },
                    {
                      dateStart: '2024-10-01',
                      dateEnd: '2024-11-30',
                      producto: 'Cebada en grano',
                      grupo: 'Cereales',
                      superficieTotal: '1,25',
                      superficieEfectiva: '1,25',
                      cantidadCosechada: '15',
                      tipoCultivo: 'Independiente',
                      campaña: 'Parte de primavera',
                      mesesCampaña: 'oct-nov',
                      tiempoCosecha: '4 a 5 meses'
                    },
                    {
                      dateStart: '2024-12-01',
                      dateEnd: '2024-03-31',
                      producto: 'Alfalfa',
                      grupo: 'Productos del forraje, fibras, plantas vivas, corte las flores y los brotes de la flor, tabaco no procesado, y caucho natural',
                      superficieTotal: '18,15',
                      superficieEfectiva: '18,15',
                      cantidadCosechada: '296',
                      tipoCultivo: 'Independiente',
                      campaña: 'Verano y otoño',
                      mesesCampaña: 'dic-ene-feb-mar',
                      tiempoCosecha: '40 a 60 días obteniendo de 6 a 8 cortes al año'
                    },
                    {
                      dateStart: '2024-10-01',
                      dateEnd: '2024-11-30',
                      producto: 'Cebada forrajera',
                      grupo: 'Productos del forraje, fibras, plantas vivas, corte las flores y los brotes de la flor, tabaco no procesado, y caucho natural',
                      superficieTotal: '83,9',
                      superficieEfectiva: '83,9',
                      cantidadCosechada: '1981',
                      tipoCultivo: 'Independiente',
                      campaña: 'Parte de primavera',
                      mesesCampaña: 'oct-nov',
                      tiempoCosecha: '3 a 4 meses'
                    },
                    {
                      dateStart: '2024-10-01',
                      dateEnd: '2025-02-28',
                      producto: 'Papa',
                      grupo: 'Raíces y tubérculos comestibles ricos en almidón',
                      superficieTotal: '21,45',
                      superficieEfectiva: '21,45',
                      cantidadCosechada: '379,5',
                      tipoCultivo: 'Independiente',
                      campaña: 'Parte de primavera y verano',
                      mesesCampaña: 'oct-nov-dic-ene-feb',
                      tiempoCosecha: '4 a 6 meses'
                    },
                    {
                      dateStart: '2024-11-01',
                      dateEnd: '2024-12-31',
                      producto: 'Maíz',
                      grupo: 'Cereales',
                      superficieTotal: '0,25',
                      superficieEfectiva: '0,25',
                      cantidadCosechada: '10',
                      tipoCultivo: 'Independiente',
                      campaña: 'Parte de primavera y verano',
                      mesesCampaña: 'nov-dic',
                      tiempoCosecha: '5 a 6 meses'
                    },
                    {
                      dateStart: '2024-11-01',
                      dateEnd: '2024-12-31',
                      producto: 'Quinua',
                      grupo: 'Cereales',
                      superficieTotal: '1,55',
                      superficieEfectiva: '1,55',
                      cantidadCosechada: '13,75',
                      tipoCultivo: 'Independiente',
                      campaña: 'Parte de primavera y verano',
                      mesesCampaña: 'nov-dic',
                      tiempoCosecha: '4 a 6 meses'
                    },
                    {
                      dateStart: '2025-04-01',
                      dateEnd: '2025-05-31',
                      producto: 'Trigo',
                      grupo: 'Cereales',
                      superficieTotal: '0,15',
                      superficieEfectiva: '0,15',
                      cantidadCosechada: '3',
                      tipoCultivo: 'Independiente',
                      campaña: 'Parte de otoño',
                      mesesCampaña: 'abr-may',
                      tiempoCosecha: '5 a 7 meses'
                    },
                    {
                      dateStart: '2024-09-01',
                      dateEnd: '2024-11-30',
                      producto: 'Haba verde',
                      grupo: 'Hortalizas',
                      superficieTotal: '4,5',
                      superficieEfectiva: '4,5',
                      cantidadCosechada: '68',
                      tipoCultivo: 'Independiente',
                      campaña: 'Primavera',
                      mesesCampaña: 'sep-oct-nov',
                      tiempoCosecha: '3 a 4 meses'
                    },
                    {
                        dateStart: '2024-10-01',
                        dateEnd: '2025-02-28',
                        producto: 'Oca',
                        grupo: 'Raíces y tubérculos comestibles ricos en almidón',
                        superficieTotal: '3,4',
                        superficieEfectiva: '3,4',
                        cantidadCosechada: '45,25',
                        tipoCultivo: 'Independiente',
                        campaña: 'Parte de primavera y verano',
                        mesesCampaña: 'oct-nov-dic',
                        tiempoCosecha: '6 a 8 meses'
                      },
                      {
                        dateStart: '2024-11-01',
                        dateEnd: '2025-03-31',
                        producto: 'Papaliza',
                        grupo: 'Raíces y tubérculos comestibles ricos en almidón',
                        superficieTotal: '0,25',
                        superficieEfectiva: '0,25',
                        cantidadCosechada: '2,5',
                        tipoCultivo: 'Independiente',
                        campaña: 'Verano y otoño',
                        mesesCampaña: 'nov-dic-ene-feb-mar',
                        tiempoCosecha: '4 a 5 meses'
                      },
                      {
                        dateStart: '2024-09-01',
                        dateEnd: '2025-02-28',
                        producto: 'Avena',
                        grupo: 'Cereales',
                        superficieTotal: '0,15',
                        superficieEfectiva: '0,15',
                        cantidadCosechada: '2',
                        tipoCultivo: 'Independiente',
                        campaña: 'Primavera',
                        mesesCampaña: 'sep-oct-nov',
                        tiempoCosecha: '5 a 6 meses'
                      },
                      {
                        dateStart: '2024-09-01',
                        dateEnd: '2025-02-28',
                        producto: 'Avena forrajera',
                        grupo: 'Productos del forraje, fibras, plantas vivas, corte las flores y los brotes de la flor, tabaco no procesado, y caucho natural',
                        superficieTotal: '2',
                        superficieEfectiva: '2',
                        cantidadCosechada: '200',
                        tipoCultivo: 'Independiente',
                        campaña: 'Primavera',
                        mesesCampaña: 'sep-oct-nov',
                        tiempoCosecha: '4 a 5 meses'
                      },
                      {
                        dateStart: '2025-03-01',
                        dateEnd: '2025-05-31',
                        producto: 'Nabo',
                        grupo: 'Hortalizas',
                        superficieTotal: '1,17',
                        superficieEfectiva: '1,17',
                        cantidadCosechada: '5',
                        tipoCultivo: 'Independiente',
                        campaña: 'Otoño',
                        mesesCampaña: 'mar-abr-may',
                        tiempoCosecha: '3 a 4 meses'
                      },
                      {
                        dateStart: '2024-10-01',
                        dateEnd: '2025-02-28',
                        producto: 'Cebolla',
                        grupo: 'Hortalizas',
                        superficieTotal: '0,525',
                        superficieEfectiva: '0,525',
                        cantidadCosechada: '2,78',
                        tipoCultivo: 'Independiente',
                        campaña: 'Parte de primavera y verano',
                        mesesCampaña: 'oct-nov-dic-ene-feb',
                        tiempoCosecha: '5 a 6 meses'
                      },
                      {
                        dateStart: '2024-10-01',
                        dateEnd: '2024-12-31',
                        producto: 'Zanahoria',
                        grupo: 'Hortalizas',
                        superficieTotal: '0,0625',
                        superficieEfectiva: '0,0625',
                        cantidadCosechada: '0,1',
                        tipoCultivo: 'Anual con Anual',
                        campaña: 'Parte de primavera y verano',
                        mesesCampaña: 'oct-nov-dic',
                        tiempoCosecha: '4 a 5 meses'
                      },
                      {
                        dateStart: '2024-10-01',
                        dateEnd: '2024-12-31',
                        producto: 'Arveja verde',
                        grupo: 'Hortalizas',
                        superficieTotal: '0,25',
                        superficieEfectiva: '0,25',
                        cantidadCosechada: '2,25',
                        tipoCultivo: 'Independiente',
                        campaña: 'Parte de primavera y verano',
                        mesesCampaña: 'oct-nov-dic',
                        tiempoCosecha: '3 a 4 meses'
                      },
                      {
                        dateStart: '2025-03-01',
                        dateEnd: '2025-05-31',
                        producto: 'Ajo',
                        grupo: 'Hortalizas',
                        superficieTotal: '0,2',
                        superficieEfectiva: '0,2',
                        cantidadCosechada: '0,375',
                        tipoCultivo: 'Independiente',
                        campaña: 'Otoño',
                        mesesCampaña: 'mar-abr-may',
                        tiempoCosecha: '6 a 7 meses'
                      },
                      {
                        dateStart: '2024-10-01',
                        dateEnd: '2025-02-28',
                        producto: 'Izaño',
                        grupo: 'Raíces y tubérculos comestibles ricos en almidón',
                        superficieTotal: '0,1',
                        superficieEfectiva: '0,1',
                        cantidadCosechada: '0,5',
                        tipoCultivo: 'Independiente',
                        campaña: 'Parte de primavera y verano',
                        mesesCampaña: 'oct-nov-dic-ene-feb',
                        tiempoCosecha: '7 a 9 meses'
                      }
            ],
            modals: {
              calendarioAgricola: false,
              ubicacion: false,
              lineasProduccion: false,
              pruebasPiloto: false,
              catalogo: false,
              proyecciones: false,
              comercializacion: false,
              sucesion: false,
              solicitarPedido: false,
              galeria: false
            },
            currentSucesion: null,
            lineasProduccion: [
              {
                producto: 'Cebolla Arequipeña',
                familia: 'Hortalizas',
                descripcion: 'Cebollas frescas y orgánicas, cultivadas en nuestras tierras. Disponemos de tres tamaños: grandes, medianos y pequeños. Perfectas para todo tipo de platos y recetas.',
                superficieTotal: '2 Ha',
                superficieEfectiva: '1.5 Ha',
                cantidadCosechada: '500 Qq',
                imagen: '/assets/img/productos/cebolla1.jpg'
              },
              {
                producto: 'Huevos Orgánicos',
                familia: 'Aves',
                descripcion: 'Huevos frescos, producidos orgánicamente. Disponemos de diferentes tamaños: AAA, AA, A, B, C, y D. Nuestros huevos son una excelente fuente de proteínas y otros nutrientes esenciales.',
                superficieTotal: '1 Ha',
                superficieEfectiva: '0.8 Ha',
                cantidadCosechada: '10000 huevos',
                imagen: '/assets/img/productos/huevorealbat.jpg'
              },
              {
                producto: 'Trucha Arco Iris',
                familia: 'Peces',
                descripcion: 'Truchas frescas de alta calidad. Ofrecemos truchas grandes de 1 kg y un lote de 3 truchas por kilo. Ideales para una dieta saludable, ricas en proteínas y ácidos grasos Omega-3.',
                superficieTotal: '0.5 Ha',
                superficieEfectiva: '0.4 Ha',
                cantidadCosechada: '200 Qq',
                imagen: '/assets/img/productos/truchasa.jpg'
              }
            ]            ,
            pruebasPiloto: [
              {
                producto: 'Papa',
                familia: 'Raíces y tubérculos',
                descripcion: 'Producción de papa.',
                superficieTotal: '3 Ha',
                superficieEfectiva: '2.5 Ha',
                cantidadCosechada: '1000 Qq',
                imagen: '/assets/img/productos/papa2.jpg'
            },
              {
                  producto: 'Cerdos',
                  familia: 'Ganado',
                  descripcion: 'Producción piloto de cerdos.',
                  superficieTotal: '1 Ha',
                  superficieEfectiva: '0.9 Ha',
                  cantidadCosechada: '300 Qq',
                  imagen: '/assets/img/productos/cerdos2.jpeg'
              },
              {
                  producto: 'Ovejas',
                  familia: 'Ganado',
                  descripcion: 'Producción piloto de ovejas.',
                  superficieTotal: '1 Ha',
                  superficieEfectiva: '0.9 Ha',
                  cantidadCosechada: '150 Qq',
                  imagen: '/assets/img/productos/ovejas.jpeg'
              },
              {
                  producto: 'Cuyes',
                  familia: 'Roedores',
                  descripcion: 'Producción piloto de cuyes.',
                  superficieTotal: '0.2 Ha',
                  superficieEfectiva: '0.15 Ha',
                  cantidadCosechada: '100 Qq',
                  imagen: '/assets/img/productos/cuyes.jpeg'
              },
              {
                  producto: 'Cebada',
                  familia: 'Cereales',
                  descripcion: 'Producción piloto de cebada.',
                  superficieTotal: '2 Ha',
                  superficieEfectiva: '1.8 Ha',
                  cantidadCosechada: '400 Qq',
                  imagen: '/assets/img/productos/cebada2.jpeg'
              },
              {
                  producto: 'Vivero',
                  familia: 'Plantas',
                  descripcion: 'Producción piloto de plantas en vivero.',
                  superficieTotal: '0.3 Ha',
                  superficieEfectiva: '0.25 Ha',
                  cantidadCosechada: '50 Qq',
                  imagen: '/assets/img/productos/vivero2.jpeg'
              },
              // Agrega más productos piloto según sea necesario
            ],
            barChart: {
              data: {
                  labels: ['Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                  datasets: [
                      {
                          label: 'Ventas de Huevos',
                          borderWidth: 2,
                          borderColor: '#727cb6',
                          backgroundColor: 'rgba(114, 124, 182, 0.3)',
                          data: [7000, 7000, 7000, 7000, 7000] // Ventas mensuales de huevos
                      },
                      {
                          label: 'Ventas de Cebollas',
                          borderWidth: 2,
                          borderColor: '#ff6384',
                          backgroundColor: 'rgba(255, 99, 132, 0.3)',
                          data: [3000, 4000, 5000, 2000, 1000] // Ventas mensuales de cebollas
                      },
                      {
                          label: 'Ventas de Papas',
                          borderWidth: 2,
                          borderColor: '#36a2eb',
                          backgroundColor: 'rgba(54, 162, 235, 0.3)',
                          data: [8000, 7000, 6000, 5000, 4000] // Ventas mensuales de papas
                      }
                  ]
              },
              options: {
                  responsive: true,
                  maintainAspectRatio: false
              }
            },
            proyecciones: [
              { producto: 'Huevos', mes: 'Agosto', ventas: '300 UU.', ingresos: '300 Bs.', crecimiento: '15%' },
              { producto: 'Huevos', mes: 'Septiembre', ventas: '700 UU.', ingresos: '700 Bs.', crecimiento: '15%' },
              { producto: 'Huevos', mes: 'Octubre', ventas: '2000 UU.', ingresos: '2000 Bs.', crecimiento: '15%' },
              { producto: 'Huevos', mes: 'Noviembre', ventas: '3500 UU.', ingresos: '3500 Bs.', crecimiento: '15%' },
              { producto: 'Huevos', mes: 'Diciembre', ventas: '3500 UU.', ingresos: '3500 Bs.', crecimiento: '15%' },
              { producto: 'Cebollas', mes: 'Agosto', ventas: '3000', ingresos: '1500 Bs.', crecimiento: '10%' },
              { producto: 'Cebollas', mes: 'Septiembre', ventas: '4000', ingresos: '2000 Bs.', crecimiento: '10%' },
              { producto: 'Cebollas', mes: 'Octubre', ventas: '5000', ingresos: '2500 Bs.', crecimiento: '10%' },
              { producto: 'Cebollas', mes: 'Noviembre', ventas: '2000', ingresos: '1000 Bs.', crecimiento: '10%' },
              { producto: 'Cebollas', mes: 'Diciembre', ventas: '1000', ingresos: '500 Bs.', crecimiento: '10%' },
              { producto: 'Papas', mes: 'Agosto', ventas: '8000', ingresos: '4000 Bs.', crecimiento: '12%' },
              { producto: 'Papas', mes: 'Septiembre', ventas: '7000', ingresos: '3500 Bs.', crecimiento: '12%' },
              { producto: 'Papas', mes: 'Octubre', ventas: '6000', ingresos: '3000 Bs.', crecimiento: '12%' },
              { producto: 'Papas', mes: 'Noviembre', ventas: '5000', ingresos: '2500 Bs.', crecimiento: '12%' },
              { producto: 'Papas', mes: 'Diciembre', ventas: '4000', ingresos: '2000 Bs.', crecimiento: '12%' }
            ],
            fields: [
                { key: 'producto', label: 'Producto' },
                { key: 'mes', label: 'Mes' },
                { key: 'ventas', label: 'Ventas Proyectadas' },
                { key: 'ingresos', label: 'Ingresos Proyectados' },
                { key: 'crecimiento', label: 'Crecimiento Proyectado' }
            ],
            productos: [
              {
                nombre: 'Huevos',
                descripcion: 'Huevos frescos de granja.',
                beneficios: 'Altos en proteínas y nutrientes esenciales.',
                envios: 'Envío a todo el país.',
                precios: [
                  'AAA: Huevos de tamaño extra grande - Bs 1.50',
                  'AA: Huevos de tamaño grande - Bs 1.30',
                  'A: Huevos de tamaño mediano - Bs 1.10',
                  'B: Huevos de tamaño pequeño - Bs 0.90',
                  'C: Huevos de tamaño muy pequeño - Bs 0.70'
                ],
                imagen: '/assets/img/productos/huevorealbat.jpg'
              },
              {
                nombre: 'Trucha',
                descripcion: 'Trucha fresca de alta calidad.',
                beneficios: 'Rica en Omega-3, perfecta para una dieta saludable.',
                envios: 'Envío a todo el país.',
                precios: [
                  'Trucha de 1 kilo: Bs 60',
                  'Trucha 3 por kilo: Bs 35'
                ],
                imagen: '/assets/img/productos/truchasa.jpg'
              },
              {
                nombre: 'Cebolla',
                descripcion: 'Cebollas frescas y orgánicas.',
                beneficios: 'Ricas en vitaminas, perfectas para ensaladas.',
                envios: 'Envío a todo el país.',
                precios: [
                  'Blanca: Bs 35 la cuarta arroba',
                  'Morada: Bs 20 la cuarta arroba',
                  'Roja: Bs 5-6 por libra',
                  'Blanca: Bs 8-10 por libra'
                ],
                imagen: '/assets/img/productos/cebolla1.jpg'
              }
            ],
            images: [
              { url: '/assets/img/productos/pez1.jpg', caption: 'Estanque de Alevines' },
              { url: '/assets/img/productos/pez2.jpg', caption: 'Estanque de Alevines' },
              { url: '/assets/img/productos/pez3.jpg', caption: 'Estanque de Alevines' },
              { url: '/assets/img/productos/pez4.jpg', caption: 'Navegación hacia los Mallas' },
              { url: '/assets/img/productos/pez5.jpg', caption: 'Alimentación a los Alevines' },
              { url: '/assets/img/productos/pez6.jpg', caption: 'Truchas Arco Iris' },
              { url: '/assets/img/productos/pez7.jpg', caption: 'Alimentación a los Alevines' },
              { url: '/assets/img/productos/pez8.jpg', caption: 'Alimentación a Truchas' },
              { url: '/assets/img/productos/actbote.jpeg', caption: 'Ingreso a las Mallas' },
              { url: '/assets/img/productos/pez10.jpg', caption: 'Creación del Puerto' },
              { url: '/assets/img/productos/pez11.jpg', caption: 'Arreglo del Bote' },
              { url: '/assets/img/productos/pez12.jpg', caption: 'Alimentación a Truchas' },
              { url: '/assets/img/productos/cuy.jpg', caption: 'Cuyes' },
              { url: '/assets/img/productos/actcuyes.jpeg', caption: 'Cuyes y Peso' },
              { url: '/assets/img/productos/cuy1.jpeg', caption: 'Cuyes ' },
              { url: '/assets/img/productos/conejo.jpg', caption: 'Conejo Orjas Largas' },
              { url: '/assets/img/productos/gallina.jpg', caption: 'Gallinas Ponedoras' },
              { url: '/assets/img/productos/image11.jpeg', caption: 'Gallina' },
              { url: '/assets/img/productos/soldados.jpeg', caption: 'Personal de Instructores y Soldados ' },
              { url: '/assets/img/productos/terreno.jpeg', caption: 'Acomodado de nutrientes a las cebollas' },
              { url: '/assets/img/productos/tractor.jpeg', caption: 'Tratamiento de tierra' },
              { url: '/assets/img/productos/riego.jpeg', caption: 'Riego Por Aspersión' },
              { url: '/assets/img/productos/papa2.jpg', caption: 'Papa' },
              { url: '/assets/img/productos/campo.jpeg', caption: 'Tratamiento de tierra' },
              { url: '/assets/img/productos/arado.jpeg', caption: 'Tratamiento de tierra' },
              { url: '/assets/img/productos/cerdo.jpeg', caption: 'Chanchos' },
              { url: '/assets/img/productos/cerdos2.jpeg', caption: 'Chanchos' },
              { url: '/assets/img/productos/ovejas.jpeg', caption: 'Ovejas' },
            ],
          pedido: {
              nombre: '',
              direccion: '',
              telefono: '',
              cantidad: 0
          },
          pedidoActual: {},
          
        };
    },
    props: {
        msg: String,
    },
    created() {
        PageOptions.pageEmpty = true;
    },
    beforeRouteLeave(to, from, next) {
        PageOptions.pageEmpty = false;
        next();
    },
    methods: {
      redirectToSantos() {
        this.$router.push("/Santos"); // Redirige a la página de BPE-VI
      },
      login() {
          this.ss.login(this.loginRequest)
              .then((result) => {
                  localStorage.setItem("persona", JSON.stringify(result.data.data));
                  localStorage.setItem("token", JSON.stringify(result.data.token));
                  this.$router.push("/Profile");
              })
              .catch((error) => {
                  this.errorBag = error.response.data.errors;
                  this.$bvToast.toast("Problema al Iniciar Sesion", {
                      title: "Error",
                      variant: "danger",
                      autoHideDelay: 5000,
                  });
              });
      },
      
      verCebolla() {
          this.Cebolla = {};
          this.$refs['view-Cebolla'].show();
      },
      showModal(modal) {
        this.modals[modal] = true;
        // if (modal === 'ubicacion') {
        //   this.loadMap();
        // }
      },
      //   loadMap() {
      //     if (typeof google !== 'undefined') {
      //       this.initMap();
      //     } else {
      //       const script = document.createElement('script');
      //       script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBxxiqckSFyeBcJm08nNkYZhy_bERNfSf8&callback=initMap';
      //       script.defer = true;
      //       script.async = true;
      //       window.initMap = this.initMap;
      //       document.head.appendChild(script);
      //     }
      //   },
      //   initMap() {
      //     if (typeof google === 'undefined') {
      //         console.error('Google Maps JavaScript API no está disponible.');
      //         return;
      //     }
      //     new google.maps.Map(document.getElementById('map'), {
      //         center: { lat: -34.397, lng: 150.644 },
      //         zoom: 8,
      //     });
      // }
      showSucesionModal(item) {
        // Supongamos que tienes una función que obtiene la información de sucesión
        this.currentSucesion = this.getSucesionInfo(item.producto);
        this.modals.sucesion = true;
      },
      getSucesionInfo(producto) {
          // Aquí deberías devolver la información de sucesión correspondiente
          const sucesionData = {
            'Cañawa': { producto: 'Cañawa', sucesion: 'Trigo o Quinua', otrosDatos: 'Datos adicionales sobre la sucesión de Cañawa.' },
            'Cebada en grano': { producto: 'Cebada en grano', sucesion: 'Cebada forrajera o Alfalfa', otrosDatos: 'Datos adicionales sobre la sucesión de Cebada en grano.' },
            'Alfalfa': { producto: 'Alfalfa', sucesion: 'Papa o Maíz', otrosDatos: 'Datos adicionales sobre la sucesión de Alfalfa.' },
            // Agrega más productos según sea necesario
        };
          return sucesionData[producto] || { producto: producto, sucesion: 'Información no disponible' };
      },
      solicitarPedido(producto) {
        this.pedidoActual = producto;
        this.pedido = {
            nombre: '',
            direccion: '',
            telefono: '',
            cantidad: 0
        };
        this.showModal('solicitarPedido');
      },
      enviarPedido() {
          console.log('Pedido enviado:', this.pedido);
          // Aquí puedes agregar la lógica para enviar el pedido al servidor.
          this.$bvToast.toast("Pedido enviado con éxito", {
              title: "Éxito",
              variant: "success",
              autoHideDelay: 5000,
          });
          this.modals.solicitarPedido = false;
      }
    },
    components: {
        DatePicker,
        BarChart,
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem("persona"));
        if (persona) {
            localStorage.clear();
        } else {
            console.log("no está logueado");
        }
    },
};