<template>
	<div>
		<div v-if="!isSpecialPage" class="fade page-sidebar-fixed page-header-fixed show page-container" :class="{
			'page-sidebar-minified': pageOptions.pageSidebarMinified,
			'page-content-full-height': pageOptions.pageContentFullHeight,
			'page-without-sidebar': pageOptions.pageWithoutSidebar,
			'page-with-right-sidebar': pageOptions.pageWithRightSidebar,
			'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
			'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
			'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
			'page-with-top-menu': pageOptions.pageWithTopMenu,
			'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
			'page-right-sidebar-toggled': pageOptions.pageMobileRightSidebarToggled || pageOptions.pageRightSidebarToggled,
			'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
			'has-scroll': pageOptions.pageBodyScrollTop
		}">
			<Header />
			<TopMenu v-if="pageOptions.pageWithTopMenu" />
			<Sidebar v-if="!pageOptions.pageWithoutSidebar" />
			<SidebarRight v-if="pageOptions.pageWithTwoSidebar" />
			<div id="content" class="content" :class="{
			'content-full-width': pageOptions.pageContentFullWidth,
			'content-inverse-mode': pageOptions.pageContentInverseMode
		}">
				<router-view></router-view>
				<vue-ins-progress-bar></vue-ins-progress-bar>
			</div>
			<Footer v-if="pageOptions.pageWithFooter" />
		</div>
		<div v-else>
			<router-view></router-view>
			<vue-ins-progress-bar></vue-ins-progress-bar>
		</div>
	</div>
</template>

<script>
import Sidebar from './components/sidebar/Sidebar.vue'
import SidebarRight from './components/sidebar-right/SidebarRight.vue'
import Header from './components/header/Header.vue'
import TopMenu from './components/top-menu/TopMenu.vue'
import Footer from './components/footer/Footer.vue'
import PageOptions from './config/PageOptions.vue'

export default {
	name: 'App',
	components: {
		Sidebar,
		SidebarRight,
		Header,
		TopMenu,
		Footer
	},
	data() {
		return {
			pageOptions: PageOptions
		}
	},
	computed: {
		isSpecialPage() {
			const specialPages = ['/Autoridades', '/', '/Calendario', '/Santos']; // Añade aquí las rutas que no deberían tener menú
			return specialPages.includes(this.$route.path);
		}
	},
	methods: {
		handleScroll() {
			this.pageOptions.pageBodyScrollTop = window.scrollY
		}
	},
	mounted() {
		this.$insProgress.finish()
	},
	created() {
		this.pageOptions.pageBodyScrollTop = window.scrollY

		window.addEventListener('scroll', this.handleScroll)

		this.$insProgress.start()

		this.$router.beforeEach((to, from, next) => {
			this.$insProgress.start()
			next()
		})
		this.$router.afterEach(() => {
			this.$insProgress.finish()
		})
	}
}
</script>