<script>
import Persona from '../pages/Persona/PersonaPage.vue'
import Departamento from '../pages/Departamento/DepartamentoPage.vue'
import Fuerza from '../pages/Fuerza/FuerzaPage.vue'
import Especialidad from '../pages/Especialidad/EspecialidadPage.vue'
import Cargo from '../pages/Cargo/CargoPage.vue' 
import Escalafon from '../pages/Escalafon/EscalafonPage.vue'
import Grado from '../pages/Grado/GradoPage.vue'
import Reparticion from '../pages/Reparticion/ReparticionPage.vue'
import Profile from '../pages/Profile/ProfilePage.vue'
import Login from '../pages/Login/LoginPage.vue'
import Sexo from '../pages/Sexo/SexoPage.vue'
import GrupoSanguineo from '../pages/GrupoSanguineo/GrupoSanguineoPage.vue'
import EstadoCivil from '../pages/EstadoCivil/EstadoCivilPage.vue'
import Arma from '../pages/Arma/ArmaPage.vue'
import Home from '../pages/Home/HomePage.vue'
import Autoridades from '../pages/Home/Autoridades.vue'
import Calendario from '../pages/Home/Calendario.vue'
import Santos from '../pages/Home/Santos.vue'

const routes = [
  { path: '/Persona', component: Persona },
  { path: '/Departamento', component: Departamento },
  { path: '/Fuerza', component: Fuerza },
  { path: '/Especialidad', component: Especialidad },
  { path: '/Cargo', component: Cargo },
  { path: '/Escalafon', component: Escalafon },
  { path: '/Grado', component: Grado },
  { path: '/Reparticion', component: Reparticion },
  { path: '/Profile', component: Profile },
  { path: '/', component: Home },
  { path: '/Sexo', component: Sexo },
  { path: '/GrupoSanguineo', component: GrupoSanguineo },
  { path: '/EstadoCivil', component: EstadoCivil },
  { path: '/Arma', component: Arma },
  { path: '/Login', component: Login },
  { path: '/Autoridades', component: Autoridades },
  { path: '/Calendario', component: Calendario },
  { path: '/Santos', component: Santos }
]

export default routes;
</script>
